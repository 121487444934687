import {
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogType,
  IChoiceGroupOption,
  IComboBoxStyles,
  IDatePickerStyles,
  IDialogContentProps,
  IModalProps,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
  Stack,
  TextField
} from '@fluentui/react';
import { IOpdracht, IPlanOpdrachtForm, planOpdrachtSchema } from 'interfaces/opdracht';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getPropertyName } from 'lib/interfaceUtils';
import FluentDatePicker from 'components/FluentDatePicker';
import FluentTimePicker from 'components/FluentTimePicker';
import { planOpdracht, resetUpsertOpdracht } from 'store/actions/opdrachten/upsert';
import { OpdrachtStatus } from 'enums/opdrachtStatus';
import { PlanReasonsLabelMap } from 'enums/planReasons';
import { peekResult, useAppDispatch, useTypedSelector } from 'store';
import { getUpsertOpdracht } from 'store/selectors/opdrachten';
import format from 'date-fns/format';

const isNaLaatsteUitvoerdatum = (opdracht: IOpdracht, date: Date): boolean => {
  if (!opdracht.gewensteUitvoerdatum) return false;

  const laatsteUitvoerdatum = new Date(opdracht.gewensteUitvoerdatum);
  laatsteUitvoerdatum.setHours(0, 0, 0, 0);

  const compareDate = new Date(date.toISOString());
  return compareDate.getTime() > laatsteUitvoerdatum.getTime();
};

const defaultTimePickerOffset = '08:00';
const pickerWidth = 250;

const timePickerStyles: Partial<IComboBoxStyles> = {
  container: { width: pickerWidth },
  optionsContainerWrapper: { height: 216 }
};

const datePickerStyles: Partial<IDatePickerStyles> = {
  textField: { width: pickerWidth }
};

interface IPlanOpdrachtProps {
  opdracht: IOpdracht;
  onCancel: () => void;
}

const PlanOpdracht: React.FC<IPlanOpdrachtProps> = ({ opdracht, onCancel }) => {
  const dispatch = useAppDispatch();

  const { status, error } = useTypedSelector(getUpsertOpdracht);

  useEffect(() => {
    dispatch(resetUpsertOpdracht());
  }, [dispatch]);

  const modalProps = useMemo(
    (): IModalProps => ({
      isBlocking: true
    }),
    []
  );

  const defaultFormvalues: IPlanOpdrachtForm = useMemo(() => {
    const van = Date.parse(opdracht?.afspraakVan ?? '');
    const vanDate = Number.isInteger(van) ? new Date(van) : null;
    const tot = Date.parse(opdracht?.afspraakTot ?? '');
    const totDate = Number.isInteger(tot) ? new Date(tot) : null;
    return {
      afspraakDatum: vanDate ? vanDate : null,
      afspraakVan: vanDate ? `${format(vanDate, 'HH:mm')}` : '',
      afspraakTot: totDate ? `${format(totDate, 'HH:mm')}` : '',
      naLaatsteUitvoerdatum: vanDate ? isNaLaatsteUitvoerdatum(opdracht, vanDate) : false,
      toelichting: ''
    };
  }, [opdracht]);

  const choiceGroupOptions: IChoiceGroupOption[] = useMemo(() => {
    return Object.keys(PlanReasonsLabelMap).map(key => {
      return {
        key,
        text: PlanReasonsLabelMap[key]
      } as IChoiceGroupOption;
    });
  }, []);

  const dialogContentProps = useMemo(
    (): IDialogContentProps => ({
      type: DialogType.normal,
      title: 'Opdracht plannen',
      subText:
        opdracht.status === OpdrachtStatus.Ontvangen
          ? 'Let op: u plant een opdracht met de status Ontvangen, hierdoor wordt de opdracht geaccepteerd.'
          : ''
    }),
    [opdracht]
  );

  const [planOpdrachtData, setPlanOpdrachtData] = useState<IPlanOpdrachtForm>(defaultFormvalues);

  const {
    handleSubmit,
    control,
    setValue,
    errors: formErrors,
    formState,
    trigger,
    watch
  } = useForm<IPlanOpdrachtForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormvalues,
    resolver: yupResolver(planOpdrachtSchema()),
    context: {
      opdracht: planOpdrachtData
    }
  });

  const redencode = watch('redencode');
  const toelichting = watch('toelichting');

  const onSave = useCallback(
    (data: IPlanOpdrachtForm) => {
      const [vanHours, vanMinutes] = data.afspraakVan.split(':');
      const [totHours, totMinutes] = data.afspraakTot.split(':');

      const van = new Date(data.afspraakDatum!);
      van.setHours(parseInt(vanHours), parseInt(vanMinutes), 0, 0);

      const tot = new Date(data.afspraakDatum!);
      tot.setHours(parseInt(totHours), parseInt(totMinutes), 0, 0);

      dispatch(resetUpsertOpdracht());
      dispatch(
        planOpdracht({
          id: opdracht.id,
          afspraakVan: van.toISOString(),
          afspraakTot: tot.toISOString(),
          redencode: data.redencode,
          toelichting: data.toelichting
        })
      )
        .then(peekResult)
        .then(success => success && onCancel());
    },
    [dispatch, onCancel, opdracht]
  );

  const handleSave = () => {
    handleSubmit(onSave)();
  };

  const handleDismiss = useCallback(() => {
    status !== 'pending' && onCancel();
  }, [status, onCancel]);

  const timeFromOffset = useMemo((): string => {
    const { afspraakDatum } = planOpdrachtData;
    if (!afspraakDatum) return defaultTimePickerOffset;

    const appointmentDate = new Date(afspraakDatum.toISOString());
    let now = new Date();
    now.setHours(0, 0, 0, 0);

    // If the appointment is for today, use the current time as an offset for the time picker.
    if (appointmentDate.getTime() === now.getTime()) {
      now = new Date();

      return `${now.getHours()}:${now.getMinutes()}`;
    }

    return defaultTimePickerOffset;
  }, [planOpdrachtData.afspraakDatum]);

  return (
    <Dialog
      hidden={false}
      onDismiss={handleDismiss}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
      minWidth={500}
    >
      <DialogContent styles={{ header: { height: 0, width: 0 }, inner: { padding: 0 } }}>
        <Stack tokens={{ childrenGap: 0 }}>
          {error && (
            <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
              {error}
            </MessageBar>
          )}
          <form onSubmit={handleSubmit(onSave)}>
            <Controller
              name={getPropertyName<IPlanOpdrachtForm>('afspraakDatum')}
              key={getPropertyName<IPlanOpdrachtForm>('afspraakDatum')}
              control={control}
              render={({ onBlur, value }) => (
                <FluentDatePicker
                  label="Datum"
                  styles={datePickerStyles}
                  isRequired={true}
                  value={value ? new Date(value) : undefined}
                  defaultValue={new Date().toLocaleString()}
                  onSelectDate={date => {
                    setValue('afspraakDatum', date);
                    let naLaatsteUitvoerdatum = false;
                    if (date && opdracht.gewensteUitvoerdatum) {
                      naLaatsteUitvoerdatum = isNaLaatsteUitvoerdatum(opdracht, date);
                    }

                    setValue('naLaatsteUitvoerdatum', naLaatsteUitvoerdatum);

                    setPlanOpdrachtData(prev => ({
                      ...prev,
                      afspraakDatum: date || null,
                      naLaatsteUitvoerdatum
                    }));

                    onBlur();
                    trigger('afspraakDatum');
                  }}
                  textField={{ errorMessage: formErrors.afspraakDatum?.message }}
                />
              )}
            />
            <Controller
              name={getPropertyName<IPlanOpdrachtForm>('afspraakVan')}
              key={getPropertyName<IPlanOpdrachtForm>('afspraakVan')}
              control={control}
              render={({ onBlur, value }) => (
                <FluentTimePicker
                  label="Tijd van"
                  styles={timePickerStyles}
                  options={[]}
                  required
                  disabled={!planOpdrachtData.afspraakDatum}
                  defaultValue={''}
                  autoComplete="off"
                  errorMessage={formErrors.afspraakVan?.message}
                  offsetKey={timeFromOffset}
                  selectedKey={value}
                  onBlur={onBlur}
                  onChange={(_, option, __, value) => {
                    const newValue = option ? (option.key as string) : value || '';

                    setValue('afspraakVan', newValue);
                    setValue('afspraakTot', '');

                    setPlanOpdrachtData(prev => ({
                      ...prev,
                      afspraakVan: newValue,
                      afspraakTot: ''
                    }));

                    trigger('afspraakVan');
                  }}
                />
              )}
            />
            <Controller
              name={getPropertyName<IPlanOpdrachtForm>('afspraakTot')}
              key={getPropertyName<IPlanOpdrachtForm>('afspraakTot')}
              control={control}
              render={({ onBlur, value }) => (
                <FluentTimePicker
                  label="Tijd tot"
                  styles={timePickerStyles}
                  options={[]}
                  required
                  disabled={!planOpdrachtData.afspraakVan}
                  defaultValue={''}
                  offsetKey={planOpdrachtData.afspraakVan}
                  selectedKey={value}
                  onBlur={onBlur}
                  errorMessage={formErrors.afspraakTot?.message}
                  onChange={(_, option, __, value) => {
                    const newValue = option ? (option.key as string) : value || '';
                    setValue('afspraakTot', newValue);

                    trigger('afspraakTot');
                  }}
                />
              )}
            />
            {planOpdrachtData.naLaatsteUitvoerdatum && (
              <>
                <Controller
                  name={getPropertyName<IPlanOpdrachtForm>('redencode')}
                  key={getPropertyName<IPlanOpdrachtForm>('redencode')}
                  control={control}
                  defaultValue={''}
                  render={({ onBlur, value, name }) => (
                    <ChoiceGroup
                      label="Reden na uiterste gereeddatum"
                      name={name}
                      required
                      selectedKey={value}
                      options={choiceGroupOptions}
                      onBlur={onBlur}
                      onChange={(_, option) => {
                        setValue('redencode', option?.key);
                        trigger('redencode');
                      }}
                    />
                  )}
                />
                <Controller
                  name={getPropertyName<IPlanOpdrachtForm>('toelichting')}
                  key={getPropertyName<IPlanOpdrachtForm>('toelichting')}
                  control={control}
                  render={({ onBlur, value, name }) => (
                    <TextField
                      label="Toelichting"
                      name={name}
                      required
                      multiline
                      autoAdjustHeight
                      styles={{
                        field: { minHeight: 150 }
                      }}
                      value={value}
                      onBlur={onBlur}
                      onChange={(_, value) => {
                        setValue('toelichting', value);
                        trigger('toelichting');
                      }}
                      errorMessage={formErrors.toelichting?.message}
                    />
                  )}
                />
              </>
            )}
            <input type="submit" style={{ visibility: 'hidden' }} />
          </form>
        </Stack>
      </DialogContent>
      <DialogFooter>
        <DefaultButton primary allowDisabledFocus disabled={
          !formState.isValid ||
          (planOpdrachtData.naLaatsteUitvoerdatum && !(redencode && toelichting))
        } onClick={handleSave}>
          {status === 'pending' ? <Spinner size={SpinnerSize.small} /> : 'Opslaan'}
        </DefaultButton>
      </DialogFooter>
    </Dialog>
  );
};

export default PlanOpdracht;
