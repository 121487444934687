import {
    DefaultEffects,
    DirectionalHint,
    IconButton,
    IContextualMenuItem,
    IContextualMenuProps,
    IStackStyles,
    NeutralColors,
    SharedColors,
    Stack,
    Text,
    TooltipHost,
} from '@fluentui/react';
import { IOpdracht } from 'interfaces/opdracht';
import { DashboardLanes } from 'enums/dashboardLanes';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PauseOpdracht from 'containers/PauseOpdracht';
import PlanOpdracht from 'containers/PlanOpdracht';
import { acceptOpdracht } from 'store/actions/opdrachten/upsert';
import RejectOpdracht from 'containers/RejectOpdracht';
import { OpdrachtSoort } from 'enums/opdrachtSoort';
import CancelOpdracht from 'containers/CancelOpdracht';
import { stringToLocaleDateString } from 'lib/internationalization';
import CompleteOpdracht from 'containers/CompleteOpdracht';
import { OpdrachtStatus } from 'enums/opdrachtStatus';
import { useTypedSelector } from 'store';
import { getOpdrachtOnderbrokenGebeurtenis } from 'store/selectors/opdrachtGebeurtenissen';
import { getGebeurtenisregelOmschrijving } from 'interfaces/opdrachtGebeurtenis';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import { fetchOpdrachtenSetPending } from 'store/actions/opdrachten/data';


const getColorFromOpdrachtsoort = (opdrachtSoort: OpdrachtSoort): string => {
    switch (opdrachtSoort) {
        case OpdrachtSoort.INS:
            return SharedColors.orange20;
        case OpdrachtSoort.MUT:
            return SharedColors.green10;
        case OpdrachtSoort.REP:
            return SharedColors.cyanBlue10;
    }
};

const getOpdrachtSoortText = (opdrachtSoort: OpdrachtSoort): string => {
    switch (opdrachtSoort) {
        case OpdrachtSoort.INS:
            return 'Inspectie';
        case OpdrachtSoort.MUT:
            return 'Mutatie';
        case OpdrachtSoort.REP:
            return 'Reparatie';
    }
};

interface IOpdrachtCardProps {
    lane: DashboardLanes;
    opdracht: IOpdracht;
    onCardClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const OpdrachtCard: React.FC<IOpdrachtCardProps> = ({ lane, opdracht, onCardClick }) => {
    const dispatch = useDispatch();

    const [onHoldReasonPickerOpen, setOnHoldReasonPickerOpen] = useState(false);
    const [planDialogOpen, setPlanDialogOpen] = useState(false);
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [completeDialogOpen, setCompleteDialogOpen] = useState(false);

    const pauseEvent = useTypedSelector(state => getOpdrachtOnderbrokenGebeurtenis(state, opdracht.id));

    const handleAccept = useCallback(() => {
        
        dispatch(fetchOpdrachtenSetPending())
        dispatch(acceptOpdracht(opdracht.id));
    }, [dispatch, opdracht]);

    const actionMenu: IContextualMenuProps = useMemo(() => {
        let items: Array<IContextualMenuItem> = [];

        const planAction: IContextualMenuItem = {
            key: 'plan',
            text: 'Plannen',
            iconProps: { iconName: 'Calendar' },
            onClick: () => setPlanDialogOpen(true),
        };

        const cancelAction: IContextualMenuItem = {
            key: 'cancel',
            text: 'Annuleren',
            iconProps: { iconName: 'Cancel' },
            onClick: () => setCancelDialogOpen(true),
        };

        const onHoldAction: IContextualMenuItem = {
            key: 'onhold',
            text: 'Onderbreken',
            iconProps: { iconName: 'CirclePause' },
            onClick: () => setOnHoldReasonPickerOpen(true),
        };

        if (lane === DashboardLanes.Starten) {
            items = [
                {
                    key: 'accept',
                    text: 'Accepteren',
                    iconProps: { iconName: 'CheckMark' },
                    onClick: () => handleAccept(),
                },
                {
                    key: 'reject',
                    text: 'Weigeren',
                    iconProps: { iconName: 'Cancel' },
                    onClick: () => setRejectDialogOpen(true),
                },
                planAction,
            ];
        } else if (lane === DashboardLanes.Plannen) {
            items = [planAction, onHoldAction, cancelAction];
        } else if (lane === DashboardLanes.Uitvoeren) {
            items = [
                {
                    key: 'complete',
                    text: 'Gereedmelden',
                    iconProps: { iconName: 'Completed' },
                    onClick: () => setCompleteDialogOpen(true),
                },
                onHoldAction,
                {
                    ...planAction,
                    text: 'Herplannen',
                },
                cancelAction,
            ];
        } else if (lane === DashboardLanes.Afwachten) {
            items = [planAction, cancelAction];
        }

        return {
            items,
        };
    }, [lane, handleAccept, setPlanDialogOpen, setRejectDialogOpen, setCancelDialogOpen, setCompleteDialogOpen]);

    const cardStackStyles: IStackStyles = useMemo(() => {
        const defaultStyles = {
            root: {
                // minHeight: 100,
                boxSizing: 'border-box',
                backgroundColor: NeutralColors.white,
                border: '1px solid #efefef',
                borderLeftWidth: 2,
                boxShadow: DefaultEffects.elevation4, ':hover': {
                    boxShadow: DefaultEffects.elevation16,
                },
                paddingTop: 0,
                paddingBottom: 10,
                paddingLeft: 10,
            },
        };

        if (!opdracht.gewensteUitvoerdatum) return defaultStyles;
        if (!isUitersteDatumVerstreken(opdracht.gewensteUitvoerdatum)) return defaultStyles;

        return {
            ...defaultStyles,
            root: {
                ...defaultStyles.root,
                borderLeftColor: '#d83b01',
            },
        };
    }, [opdracht]);

    return (
        <>
            {onHoldReasonPickerOpen && (
                <PauseOpdracht opdracht={opdracht} onCancel={() => setOnHoldReasonPickerOpen(false)} />
            )}
            {planDialogOpen && <PlanOpdracht opdracht={opdracht} onCancel={() => setPlanDialogOpen(false)} />}
            {rejectDialogOpen && <RejectOpdracht opdracht={opdracht} onCancel={() => setRejectDialogOpen(false)} />}
            {cancelDialogOpen && <CancelOpdracht opdracht={opdracht} onCancel={() => setCancelDialogOpen(false)} />}
            {completeDialogOpen &&
                <CompleteOpdracht opdracht={opdracht} onCancel={() => setCompleteDialogOpen(false)} />}

            <Stack key={opdracht.id} styles={cardStackStyles}>
                <Stack horizontal horizontalAlign="space-between">
                    <Stack.Item grow>
                        <div style={{
                            backgroundColor: getColorFromOpdrachtsoort(opdracht.soort),
                            color: NeutralColors.white,
                            fontWeight: 600,
                            padding: '6px 8px',
                            display: 'inline-block',
                            fontSize: '11px',
                            marginTop: -2,
                            boxShadow: DefaultEffects.elevation4,
                        }}>
                            {getOpdrachtSoortText(opdracht.soort)}
                        </div>
                    </Stack.Item>
                    <Stack.Item>
                        {opdracht.typering && opdracht.typering === 'MAO' && opdracht.status === OpdrachtStatus.Ontvangen && (
                            <div style={{
                                backgroundColor: NeutralColors.white,
                                color: SharedColors.cyanBlue10,
                                fontWeight: 700,
                                padding: '3px 6px',
                                display: 'inline-block',
                                fontSize: '10px',
                                marginTop: 6,
                                marginRight: 4,
                                float: 'right',
                                boxShadow: DefaultEffects.roundedCorner2,
                                borderRadius: 30,
                                border: '1px solid #efefef',
                                borderColor: SharedColors.cyanBlue10,
                            }}>
                                Gewijzigd
                            </div>
                        )}
                    </Stack.Item>
                    <Stack.Item>
                        <IconButton
                            styles={{ menuIcon: { width: 'auto' } }}
                            iconProps={{ iconName: 'MoreVertical' }}
                            menuProps={actionMenu}
                            hidden={true}
                            menuIconProps={{ iconName: '' }}
                        />
                    </Stack.Item>
                </Stack>
                <Stack
                    id={opdracht.id.toString()}
                    styles={{ root: { margin: 0, userSelect: 'none', padding: '0px 16px 0px 6px' } }}
                    tokens={{ childrenGap: 12 }}
                    onClick={onCardClick}
                >
                    <Stack.Item styles={{ root: { cursor: 'pointer' } }}>
                        <TooltipHost id={opdracht.id.toString()}
                            content={opdracht.vrijeTekst}
                            calloutProps={{
                                gapSpace: 0,
                                target: `#vrijetext-${opdracht.id.toString()}`
                            }}
                            directionalHint={DirectionalHint.topCenter}
                            styles={{ root: { display: 'block' }, }}>
                            <div id={`vrijetext-${opdracht.id.toString()}`}>
                                <Text block nowrap variant="smallPlus" styles={{ root: { fontWeight: 400, paddingBottom: 2 } }}>
                                    {`${opdracht.nummer} ${opdracht.subnummer ? ' - ' + opdracht.subnummer : ''}`}
                                </Text>
                                <Text block={true} nowrap={true} variant="smallPlus"
                                    styles={{ root: { fontWeight: 400, paddingBottom: 6 } }}
                                >
                                    {opdracht.vrijeTekst}
                                </Text>
                            </div>
                        </TooltipHost>
                        <Text block nowrap variant="smallPlus" styles={{ root: { fontWeight: 600 } }}>
                            {`${opdracht.straatnaam || '** straatnaam ontbreekt **'} ${opdracht.huisnummer || ''} ${opdracht.huisletter || ''} ${opdracht.huisnummerToevoeging || ''}`}
                        </Text>
                        <Text block nowrap variant="smallPlus" styles={{ root: { fontWeight: 600 } }}>
                            {`${opdracht.postcode || '****'} ${opdracht.plaats || '** plaats ontbreekt **'}`}
                        </Text>
                        <Text block nowrap variant="smallPlus" styles={{ root: { fontWeight: 600 } }}>
                            {opdracht.contactpersoon}
                        </Text>
                        {opdracht.afspraakVan!! && (
                            <Text block nowrap variant="smallPlus">
                                <AfspraakDetails soort={opdracht.soort} afspraakVan={opdracht.afspraakVan}
                                    afspraakTot={opdracht.afspraakTot}></AfspraakDetails>
                            </Text>
                        )}
                        <Text block nowrap variant="smallPlus" styles={{ root: { fontWeight: 600, paddingTop: 4 } }}
                            style={isUitersteDatumVerstreken(opdracht.gewensteUitvoerdatum) ? { color: '#d83b01' } : {}}>
                            Uiterlijk: {stringToLocaleDateString(opdracht.gewensteUitvoerdatum)}
                        </Text>
                        {opdracht.status === OpdrachtStatus.Onderbroken && pauseEvent && (
                            <Text block nowrap variant="smallPlus">
                                {getGebeurtenisregelOmschrijving(pauseEvent.regels[0].actie)}
                            </Text>
                        )}
                    </Stack.Item>
                </Stack>
            </Stack>
        </>
    );
};


interface IAfspraakProps {
    soort: OpdrachtSoort,
    afspraakVan?: string
    afspraakTot?: string
}

export const AfspraakDetails = ({ soort, afspraakVan, afspraakTot }: IAfspraakProps) => {

    return (
        <>
            {soort === 'MUT' && (
                <>
                    <div style={afspraakDetailsTitleStyle}>Planning</div>
                    <div>{afspraakVan && format(parseISO(afspraakVan), 'dd-MM-yyyy HH:mm')}</div>
                    <div>{afspraakTot && format(parseISO(afspraakTot), 'dd-MM-yyyy HH:mm')}</div>
                </>
            )}
            {soort !== 'MUT' && (
                <>
                    <div style={afspraakDetailsTitleStyle}>Afspraak</div>
                    <div>{afspraakVan && format(parseISO(afspraakVan), 'dd-MM-yyyy HH:mm')}
                        {afspraakTot && ' - ' + format(parseISO(afspraakTot), 'HH:mm')}</div>
                </>
            )}
        </>
    );
};

export function isUitersteDatumVerstreken(gewensteUitvoerdatum) {
    return gewensteUitvoerdatum && new Date().getTime() >= new Date(gewensteUitvoerdatum).getTime();
}

const afspraakDetailsTitleStyle: React.CSSProperties = {
    paddingTop: 4,
    fontWeight: 600,
};


export default OpdrachtCard;
